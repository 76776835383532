// 
// authentication.scss
//

.bg-pattern {
    background-image: url("../images/bg-pattern.png");
    background-size: cover;
    background-position: center;
}

// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

